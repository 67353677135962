<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Produtos Incentivados
    </v-card-title>
    <master-detail
      formTitle="Cadastro de Produtos Incentivados"
      :cols="cols"
      :opts="opts"
      :resourceUrl="resourceUrl"
      descriptionProperty="nome"
      :disableActContextOnClick="true"
      :contextOptions="options"
      :canDelete="!canView"
      :canEdit="!canView"
      :canView="canView"
      :hasNewButton="!canView"
      @loadedRows="loadedRows = $event"
      @onOpenFormDialog="selectedItem = $event"
    >
      <async-export-button type="produtos"></async-export-button>
    </master-detail>
  </v-card>
</template>

<script>
import { UserTypeEnum } from '@/core/enums/user-types';
import { mapGetters } from 'vuex';
export default {
  components: {
    'async-export-button': () => import("@/components/async-export-button.vue"),
    'master-detail': () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['user']),
    client: function () {
      return this.getClient();
    },
    resourceUrl: function () {
      return `/v1/faturamento/produtos/${this.client.clientId}`;
    },
    canView: function () {
      // return this.$route.meta.viewMode === true;
      return [UserTypeEnum.GERENCIAL_CLIENTE, UserTypeEnum.COLABORADOR].includes(this.user.tipo_usuario);
    },
    cols: function () {
      const isPPB = this.selectedItem?.incentivo === 'PPB';
      const htmlLinkElements = this.loadedRows.map((row) => {
        let { id, url_mcti, incentivo } = row;
        let element = url_mcti 
          ? `<a class="dynamic-a-element" href="${url_mcti}" target="_blank" title="Acessar portaria em nova aba">Acessar</a>`
          : incentivo === 'PPB'
            ? '<a disabled title="Não disponivel">Link ausente</a>'
            : '<span />';
        return { id, element };
      });

      return [
        {
          key: "codigoProduto",
          name: "Código",
          type: this.$fieldTypes.TEXT,
          editable: false,
          hideInform: true,
        },
        {
          key: "abreviacao",
          name: "Abreviação",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          rules: [{ rule: "required" }],
        },
        {
          key: "nome",
          name: "Produto",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          rules: [{ rule: "required" }],
        },
        {
          key: "ncm",
          name: "NCM",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          rules: [{ rule: "required" }],
        },
        {
          key: "tecnologia_nacional",
          name: "Tecnologia Nacional",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
          align: 0,
        },
        {
          key: "incentivo",
          name: "Incentivo",
          type: this.$fieldTypes.SELECT,
          colSize: 6,
          rel: { to: "incentivo", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
          align: 0,
        },
        {
          key: "portaria",
          name: "Portaria de Habilitação",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          hideInform: !isPPB,
        },
        {
          key: "data_portaria",
          name: "Data DOU",
          type: this.$fieldTypes.DATE,
          colSize: 6,
          align: 0,
          hideInform: !isPPB,
        },
        {
          key: "url_mcti",
          name: "Publicação MCT",
          type: this.$fieldTypes.TEXT,
          colSize: 12,
          hideInTable: true,
          hideInform: !isPPB,
        },
        {
          key: "id",
          name: "Publicação MCT",
          type: this.$fieldTypes.HTML,
          rel: { data: htmlLinkElements, key: "id", name: "element" },
          hideInform: true,
          align: 0,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
          width: '150px',
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
          width: '150px',
        },
      ];
    },
    options: function () {
      const items = [
        {
          name: "Ver Alíquota Adicional",
          limit: 1,
          class: { "btn-info": true, "btn-md": true },
          show: this.userHasAccess("Faturamento.resource.geral"), // TODO temporário. Tem que ser sempre igual a permissão de Produtos Incentivados
          cb: (e) => {
            this.$router.push({ name: "listagem-aliquota-adicional", params: { codigoProduto: e.codigoProduto } });
          },
        },
        {
          name: "Ver Modelos Incentivados",
          limit: 1,
          class: { "btn-info": true, "btn-md": true },
          show: this.userHasAccess("Faturamento.resource.geral"), // TODO temporário. Tem que ser sempre igual a permissão de Produtos Incentivados
          cb: (e) => {
            this.$router.push({ name: "listagem-modelos-incentivados", params: { codigoProduto: e.codigoProduto } });
          },
        },
        {
          name: "Ver NCM",
          limit: 1,
          class: { "btn-info": true, "btn-md": true },
          show: this.userHasAccess("Faturamento.resource.geral"), // TODO temporário. Tem que ser sempre igual a permissão de Produtos Incentivados
          cb: (e) => {
            sessionStorage.setItem('nomeProduto', e.nome);
            this.$router.push({ name: "listagem-ncm", params: { codigoProduto: e.codigoProduto } });
          },
        },
      ];

      return items.filter(({ show }) => show);
    },
  },
  data: function () {
    return {
      loadedRows: [],
      selectedItem: {},
      opts: {
        incentivo: [
          {
            id: "PPB",
            nome: "PPB",
          },
          {
            id: "TecNac",
            nome: "TecNac",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep a.dynamic-a-element {
  color: #007bff;
  text-decoration: underline;
}
</style>
